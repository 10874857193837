import React from "react";
import {createBrowserRouter} from "react-router-dom";
import Root from "../pages/root";
import Login from "../pages/login";
import ErrorPage from "../pages/errorPage";
import ReservationsView from "../pages/reservations/reservation-view";

const router = createBrowserRouter([
    {
        path: '/',
        element: <Root/>,
        errorElement: <ErrorPage/>
    },
    {
        path: '/{:apartment}',
        element: <Root/>,
        errorElement: <ErrorPage/>
    },
    {
        path: '/login',
        element: <Login/>,
        errorElement: <ErrorPage/>
    },
    {
        path: '/reservations',
        element: <ReservationsView/>
    }
])

export default router;
