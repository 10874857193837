import {useCallback, useEffect, useState} from "react";

let logoutTimer: NodeJS.Timeout;

export const useAuth = () => {
    const [token, setToken] = useState<string|null>(null);
    const [tokenExpirationDate, setTokenExpirationDate] = useState<Date|null>();
    const [userId, setUserId] = useState<string|null>(null);

    const login = useCallback((uid: string, token: string, expirationDate: Date) => {
        setToken(token);
        setUserId(uid);
        const tokenExpirationDate =
            expirationDate || new Date(new Date().getTime() + 1000 * 60 * 60);
        setTokenExpirationDate(tokenExpirationDate);
        console.log("Zapisuej dane użytkownika w localstorage");
        localStorage.setItem(
            'userData',
            JSON.stringify({
                userId: uid,
                token: token,
                expiration: tokenExpirationDate.toISOString()
            })
        );
    }, []);

    const logout = useCallback(() => {
        setToken(null);
        setTokenExpirationDate(null);
        setUserId(null);
        localStorage.removeItem('userData');
    }, []);

    useEffect(() => {
        if (token && tokenExpirationDate) {
            const remainingTime = tokenExpirationDate.getTime() - new Date().getTime();
            logoutTimer = setTimeout(logout, remainingTime);
        } else {
            clearTimeout(logoutTimer);
        }
    }, [token, logout, tokenExpirationDate]);

    useEffect(() => {
        const userData = localStorage.getItem('userData');
        const storedData = userData != null ? JSON.parse(userData) : null;
        if (
            storedData &&
            storedData.token &&
            new Date(storedData.expiration) > new Date()
        ) {
            login(storedData.userId, storedData.token, new Date(storedData.expiration));
        }
    }, [login]);

    return { token, login, logout, userId };
};