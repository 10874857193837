import axios from "axios";
import {Reservation} from "../model/reservation";
import {ErrorResponse} from "../model/error-response";

export function findAllReservations(onSuccess: Function, onError: Function) {
    axios({
        method: 'get',
        url: '/reservations'
    })
        .then((response: any) => {
            onSuccess(response.data);
        })
        .catch((error: any) => {
            onError(error.response.data);
        })
}

export function findAllReservationsForApartment(apartment: string, onSuccess: Function, onError: Function) {
    axios({
        method: 'get',
        url: `/reservations/apartments/${apartment}`
    })
        .then((response: any) => {
            onSuccess(response.data);
        })
        .catch((error: any) => {
            onError(error.response.data);
        })
}

export function addReservation(reservation: Reservation, onSuccess: (reservation: Reservation) => void, onError: (error: ErrorResponse) => void) {
    axios({
        method: 'post',
        url: `/reservations`,
        data: reservation
    })
        .then((response: any) => {
            onSuccess(response.data);
        })
        .catch((error: any) => {
            onError(error.response.data);
        })
}

export function deleteReservation(reservationId: string, onSuccess: Function, onError: Function) {
    axios({
        method: 'delete',
        url: `/reservations/${reservationId}`
    })
        .then((response: any) => {
            onSuccess(response.data.id);
        })
        .catch((error: any) => {
            onError(error.response.data);
        })
}
