import axios from "axios";

export function login(login: string, password: string, onSuccess: Function, onError: Function) {
    console.log(`Próba logowanie loginem: ${login} i hasłem: ${password}`);
    axios({
        method: 'post',
        url: '/user/login',
        data: {
            'login': login,
            'password': password
        }
    })
        .then((response) => {
            onSuccess(response);
        })
        .catch((error) => {
            console.log(error)
            onError(error.response.data);
        })
}