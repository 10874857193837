import "./footer.scss";

export default function Footer() {

    return (
        <div className="footer">
            <div className="footer__container">
                <div className="footer__item">
                    Regulamin
                </div>
            </div>

        </div>
    )
}
