import React, {FunctionComponent, useEffect, useState} from "react";
import {Navigate} from "react-router-dom";

type MiddlewareProps = {
    children: React.ReactNode; // 👈️ type children
};

const Middleware: FunctionComponent<MiddlewareProps> = (props) => {
    const [needLoginPage, setNeedLoginPage] = useState(false);

    useEffect(() => {
        console.log("Sprawdzam czy jest zalogowany")
        if(localStorage.getItem("userData")) {
            console.log("Jest zalogowany")
            setNeedLoginPage(false);
        } else {
            console.log("Nie jest zalogowany")
            setNeedLoginPage(true);
        }
    }, [])

    return (
        <>
            {needLoginPage && <Navigate to='/login' replace={true}/>}
            {!needLoginPage && <>{props.children}</>}
        </>
    )
}

export default Middleware;