import "./description.scss";
import {Equipment, Room, rooms} from "./equipment";
import {useContext} from "react";
import {ApartmentContext} from "../../../context/apartment-context";
import {Element} from "react-scroll";

export function Description() {

    const apartmentContext = useContext(ApartmentContext);

    return (
        <div className="description">
            <div className="description__container">
                {/*<div className="description__section">*/}
                {/*    <Container>*/}
                {/*        <Row>*/}
                {/*            <Col lg={3} sm={6} xs={12}>*/}
                {/*                <div className="description__buzzwords">*/}
                {/*                    <div className="description__buzzwords-value">*/}
                {/*                        <span>2</span>*/}
                {/*                    </div>*/}
                {/*                    <div className="description__buzzwords-text">*/}
                {/*                        <span>mieszkania</span>*/}
                {/*                    </div>*/}
                {/*                </div>*/}
                {/*            </Col>*/}
                {/*            <Col lg={3} sm={6} xs={12}>*/}
                {/*                <div className="description__buzzwords">*/}
                {/*                    <div className="description__buzzwords-value">*/}
                {/*                        <span>50</span>*/}
                {/*                    </div>*/}
                {/*                    <div className="description__buzzwords-text">*/}
                {/*                        <span>metrów każde</span>*/}
                {/*                    </div>*/}
                {/*                </div>*/}
                {/*            </Col>*/}
                {/*            <Col lg={3} sm={6} xs={12}>*/}
                {/*                <div className="description__buzzwords">*/}
                {/*                    <div className="description__buzzwords-value">*/}
                {/*                        <span>4 - 6</span>*/}
                {/*                    </div>*/}
                {/*                    <div className="description__buzzwords-text">*/}
                {/*                        <span>miejsc noclegowych</span>*/}
                {/*                    </div>*/}
                {/*                </div>*/}
                {/*            </Col>*/}
                {/*            <Col lg={3} sm={6} xs={12}>*/}
                {/*                <div className="description__buzzwords">*/}
                {/*                    <div className="description__buzzwords-value">*/}
                {/*                        <span>200+</span>*/}
                {/*                    </div>*/}
                {/*                    <div className="description__buzzwords-text">*/}
                {/*                        <span>zadowolonych klientów</span>*/}
                {/*                    </div>*/}
                {/*                </div>*/}
                {/*            </Col>*/}
                {/*        </Row>*/}
                {/*    </Container>*/}
                {/*</div>*/}
                <Element name="equipment" className="description__section">
                    <h1>
                        Wyposażenie
                    </h1>
                    <div className="description__equipments">
                        {rooms && rooms.map((room: Room, roomIndex: number) => {
                                return (
                                    <div key={"room_" + roomIndex}>
                                        <div className="description__equipment-header">
                                            <h2>{room.name}</h2>
                                        </div>
                                        <div className="description__equipment-container">
                                            {room.equipments && room.equipments.filter(eq => eq.exclusions === undefined || eq.exclusions !== apartmentContext.apartment).map((eq: Equipment, index: number) => {
                                                return (
                                                    <div className="description__equipment-cell" key={"equipment_" + index}>
                                                        <div className="description__equipment-icon">
                                                            <img src={eq.icon} alt={eq.description}/>
                                                        </div>
                                                        <div className="description__equipment-label">
                                                            <span>{eq.description}</span>
                                                        </div>
                                                    </div>
                                                )
                                            })}
                                        </div>
                                    </div>
                                )
                            }
                        )}
                    </div>
                </Element>
            </div>
        </div>
    )
}
