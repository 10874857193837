import Navbar from "./sections/navbar/navbar";
import Faq from "./sections/faq/faq";
import CalendarPage from "./sections/calendar/calendar-page";
import Gallery from "./sections/gallery/gallery";
import Map from "./sections/map/map";
import {Description} from "./sections/description/description";
import '../styles/global.scss';
import MainPhotos from "./sections/main-photos/main-photos";
import {useContext} from "react";
import {ApartmentContext} from "../context/apartment-context";
import ApartmentSelector from "./sections/apartment-selector/apartment-selector";
import Contact from "./sections/contact/contact";
import Footer from "./sections/footer/footer";
import Introduction from "./sections/introduction/introduction";

export default function Root() {

    const apartmentContext = useContext(ApartmentContext);

    const checkIfApartmentIsSelected = () => {
        if (apartmentContext.apartment) {
            return (
                <>
                    <Description/>
                    <Gallery/>
                    <Map/>
                    <Faq/>
                    {/*<CalendarPage/>*/}
                </>
            )
        } else {
            return (
                <ApartmentSelector/>
            )
        }
    }

    return (
        <div className="root">
            <Navbar/>
            <MainPhotos/>
            <div className="content">
                <Introduction/>
                {checkIfApartmentIsSelected()}
                <Contact/>
            </div>
            <Footer/>
        </div>
    )
}
