import "./apartment-selector.scss";
import {useContext} from "react";
import {ApartmentContext} from "../../../context/apartment-context";
import {ApartmentEnum} from "../../../model/enums/apartment.enum";
import {Element, Link} from "react-scroll";

export default function ApartmentSelector() {

    const apartmentContext = useContext(ApartmentContext);

    const onApartmentSelect = (apartment: ApartmentEnum) => {
        apartmentContext.setApartment(apartment);
    }

    return (
        <Element name="apartment-selector" className="apartment-selector">
            <div className="apartment-selector__container">
                <h1>
                    Wybierz
                </h1>
                <Link to="introduction" smooth={false} offset={-50} delay={100} className="apartment-selector__apartments">
                    <div className="apartment-selector__option"
                         onClick={() => onApartmentSelect(ApartmentEnum.LUNA)}>
                        <div className="apartment-selector__image">
                            <img src="/images/views/wizytowka_luna.jpg" alt="Luna rzut z góry"/>
                        </div>
                        <div className="apartment-selector__label">
                            <span>Luna</span>
                        </div>
                    </div>
                    <div className="apartment-selector__option"
                         onClick={() => onApartmentSelect(ApartmentEnum.WEGA)}>
                        <div className="apartment-selector__image">
                            <img src="/images/views/wizytowka_wega.jpg" alt="Wega rzut z góry"/>
                        </div>
                        <div className="apartment-selector__label">
                            <span>Wega</span>
                        </div>
                    </div>
                </Link>
            </div>
        </Element>
    )
}
