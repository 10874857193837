import "./main.photo.scss";
import {useContext} from "react";
import {ApartmentContext} from "../../../context/apartment-context";
import {ApartmentEnum} from "../../../model/enums/apartment.enum";
import {Element, Link} from "react-scroll";


export default function MainPhotos() {

    const apartmentContext = useContext(ApartmentContext);

    const onClickApartment = (apartment: ApartmentEnum) => {
        apartmentContext.setApartment(apartment);
    }

    return (
        <Element name="home" className="main-photos">
            <div className="main-photos__container">
                <div className="main-photos__photo">
                    <img src="/images/morze_krzewy_dociete_mniejsze.jpg"/>
                </div>
                <div className="main-photos__blur"></div>
                <div className="main-photos__text-container">
                    <div className="main-photo__title-container">
                        <div className="main-photos__title">
                            Apartamenty nad morzem <br/> w Gdańsku
                        </div>
                    </div>
                    <div className="main-photos__buzzwords-container">
                        <div className="main-photos__left-buzzword">
                            Odpoczywaj nad morzem
                        </div>
                        <div className="main-photos__right-buzzword">
                            Zwiedzaj Trójmiasto
                        </div>
                    </div>
                    <div className="main-photo__apartments">
                        <Link to="introduction" className="main-photo__apartments__cell" onClick={() => onClickApartment(ApartmentEnum.LUNA)} smooth={false} offset={-50} delay={100}>
                            <span>Luna</span>
                        </Link>
                        <div className="main-photo__apartments__arrow" >
                            <img className="left-arrow" src="images/icons/white-left-arrow-icon.svg" alt="left arrow"></img>
                            <div className="main-photo__apartments__text">
                                <span>wybierz apartament</span>
                            </div>
                            <img className="right-arrow" src="images/icons/white-left-arrow-icon.svg" alt="left arrow"></img>
                        </div>
                        <Link to="introduction" className="main-photo__apartments__cell" onClick={() => onClickApartment(ApartmentEnum.WEGA)} smooth={false} offset={-50} delay={100}>
                            <span>Wega</span>
                        </Link>
                    </div>
                </div>
            </div>

        </Element>
    )
}
