import Middleware from "../../middleware";
import React, {useEffect, useRef, useState} from "react";
import ReservationList from "./reservation-list";
import ReservationForm from "./reservation-form";
import {Reservation} from "../../model/reservation";
import {ErrorResponse} from "../../model/error-response";
import {Toast} from "primereact/toast";
import {ErrorCodesEnum} from "../../model/enums/error-codes.enum";


const HttpService = require("../../services/http")

export default function ReservationsView() {
    const toast = useRef<Toast>(null);

    const [reservations, setReservations] = useState<Reservation[]>([]);

    useEffect(() => {
        fetchReservations();
    }, []);

    async function onSubmitSuccess() {
        fetchReservations();
        showToast("Dodano rezerwację", "Sukces", "success")
    }

    async function onSubmitError(error: ErrorResponse|undefined) {
        if(error) {
            error.errorCodes.forEach(code => showToast(ErrorCodesEnum[code as keyof typeof ErrorCodesEnum]))
        }
    }

    function fetchReservations() {
        HttpService.findAllReservations(
            (results: Reservation[]) => {
                setReservations(results);
            }, () => {
                showToast("Nie udało się pobrać rezerwacji", "Błąd");
            })
    }

    async function onDeleteReservation(reservationId: string | undefined) {
        if (reservationId === undefined) {
            return;
        }

        HttpService.deleteReservation(
            reservationId,
            (reservationId: string) => {
                setReservations(reservations.filter(it => it.id !== reservationId));
            },
            () => {
                showToast("Nie udało się usunąć rezerwacji", "Błąd");
            }
        )
    }

    function showToast(message: string, title: string = "Błąd walidacji", severity: "success" | "error" | "info" | "warn" | undefined = 'error') {
        if(toast.current) {
            toast.current.show({ severity: severity, summary: title, detail: message});
        }
    }

    return (
        <Middleware>
            <div className="reservations-view">
                <Toast ref={toast} position="bottom-right"/>
                <ReservationForm onSubmitSuccess={onSubmitSuccess} onSubmitError={onSubmitError}/>
                <ReservationList reservations={reservations} onDelete={onDeleteReservation}/>
            </div>
        </Middleware>
    )
}
