import {Button, Col, Container, Form, Row} from "react-bootstrap";
import React from "react";
import {InputText} from "primereact/inputtext";
import {Calendar} from "primereact/calendar";
import {Dropdown} from "primereact/dropdown";
import {ApartmentEnum} from "../../model/enums/apartment.enum";
import {useFormik} from "formik";
import * as Yup from 'yup';
import {InputNumber} from "primereact/inputnumber";
import {Reservation} from "../../model/reservation";
import {ErrorResponse} from "../../model/error-response";

const HttpService = require("../../services/http")

interface Apartment {
    name: string;
    code: ApartmentEnum;
}

interface FormValues {
    who: string,
    from: Date | string,
    to: Date | string,
    apartment: ApartmentEnum,
    numberOfPeople: number
}

interface ReservationFormProps {
    onSubmitSuccess: (reservation: Reservation) => void,
    onSubmitError: (error: ErrorResponse|undefined) => void
}

export default function ReservationForm(props: ReservationFormProps) {
    const initialFormValues: FormValues = {who: '', from: '', to: '', apartment: ApartmentEnum.LUNA, numberOfPeople: 5};
    const minDate = new Date(2023, 5, 0);
    const maxDate = new Date(2023, 8, 29);
    const defaultDate = new Date(2023, 6, 0);

    const validationSchema = Yup.object({
        who: Yup.string()
            .required("Nie podano kogo dotyczy rezerwacja"),
        from: Yup.date()
            .required("Data od kiedy jest wymagana"),
        to: Yup.date()
            .min(Yup.ref('from'), "Data do nie może być przed datą od")
            .required("Data do kiedy jest wymagana"),
        numberOfPeople: Yup.number()
            .max(7, "Liczba osób nie może przekraczać 7")
            .min(1, "Liczba osób nie może być mniejsza niż 1")
            .required("Liczba osób jest wymagana")
    })

    const formik = useFormik({
        initialValues: initialFormValues,
        validationSchema: validationSchema,
        onSubmit: onSubmitEvent,
    })

    const apartments: Apartment[] = [
        {name: 'Luna (15)', code: ApartmentEnum.LUNA},
        {name: 'Wega (19)', code: ApartmentEnum.WEGA},
    ];

    const isFormFieldInvalid = (name: string) => (formik.touched[name as keyof FormValues] && formik.errors[name as keyof FormValues]);

    async function onSubmitEvent(values: FormValues) {
        const fromDate = values.from as Date;
        const toDate = values.to as Date;

        HttpService.addReservation(
            new Reservation(
                values.who,
                (new Date(Date.UTC(fromDate.getFullYear(), fromDate.getMonth(), fromDate.getDate()))),
                (new Date(Date.UTC(toDate.getFullYear(), toDate.getMonth(), toDate.getDate()))),
                values.numberOfPeople,
                values.apartment),
            (reservation: Reservation) => {
                props.onSubmitSuccess(reservation);
                formik.resetForm();
            },
            (error: ErrorResponse) => {
                props.onSubmitError(error);
            });
    }

    return (
        <Form onSubmit={formik.handleSubmit}>
            <Container fluid>
                <Row className="mb-3">
                    <Col>
                        <div className="d-flex flex-column">
                            <label htmlFor="who">Kto</label>
                            <InputText id="who" name="who" value={formik.values.who}
                                       className={isFormFieldInvalid('who') ? "p-invalid" : ""}
                                       onChange={formik.handleChange}/>
                            {isFormFieldInvalid('who') ?
                                <small id="who-error" className="text-danger">
                                    {formik.errors.who}
                                </small> : null
                            }
                        </div>
                    </Col>
                </Row>
                <Row className="mb-3">
                    <Col xs={12} lg={6}>
                        <div className="d-flex flex-column">
                            <label htmlFor="from">Od kiedy</label>
                            <Calendar id="from" name="from"
                                      value={formik.values.from}
                                      minDate={minDate} maxDate={maxDate}
                                      viewDate={defaultDate}
                                      className={isFormFieldInvalid('from') ? "p-invalid" : ""}
                                      onChange={formik.handleChange} readOnlyInput/>
                            {isFormFieldInvalid('from') ?
                                <small id="from-error" className="text-danger">
                                    {formik.errors.from}
                                </small> : null
                            }
                        </div>
                    </Col>
                    <Col xs={12} lg={6}>
                        <div className="d-flex flex-column">
                            <label htmlFor="to">Do kiedy</label>
                            <Calendar id="to" name="to"
                                      value={formik.values.to}
                                      minDate={minDate} maxDate={maxDate}
                                      viewDate={defaultDate} yearRange="2023:2023"
                                      className={isFormFieldInvalid('to') ? "p-invalid" : ""}
                                      onChange={formik.handleChange} readOnlyInput/>
                            {isFormFieldInvalid('to') ?
                                <small id="to-error" className="text-danger">
                                    {formik.errors.to}
                                </small> : null
                            }
                        </div>
                    </Col>
                </Row>
                <Row className="mb-3">
                    <Col>
                        <div className="d-flex flex-column">
                            <label htmlFor="apartment">Mieszkanie</label>
                            <Dropdown id="apartment" name="apartment"
                                      value={formik.values.apartment}
                                      onChange={formik.handleChange}
                                      className={isFormFieldInvalid('apartment') ? "w-full md:w-14rem p-invalid" : "w-full md:w-14rem"}
                                      options={apartments} optionLabel="name" optionValue="code"
                                      placeholder="Wybierz mieszkanie"/>
                        </div>
                    </Col>
                </Row>
                <Row className="mb-3">
                    <Col>
                        <div className="d-flex flex-column">
                            <label htmlFor="numberOfPeople">Liczba osób</label>
                            <InputNumber id="numberOfPeople" name="numberOfPeople" value={formik.values.numberOfPeople}
                                         className={isFormFieldInvalid('numberOfPeople') ? "p-invalid" : ""}
                                         onValueChange={(e) => {
                                             formik.setFieldValue('numberOfPeople', e.value);
                                         }}/>
                            {isFormFieldInvalid('numberOfPeople') ?
                                <small id="numberOfPeople-error" className="text-danger">
                                    {formik.errors.numberOfPeople}
                                </small> : null
                            }
                        </div>
                    </Col>
                </Row>
                <Row className="mb-3 justify-content-center">
                    <Col>
                        <Button variant="primary" type="submit">
                            Zapisz
                        </Button>
                    </Col>
                </Row>
            </Container>
        </Form>
    )
}
