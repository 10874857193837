import {Button, Table} from "react-bootstrap";
import React from "react";
import {Reservation} from "../../model/reservation";
import moment from "moment";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPenToSquare, faTrash} from "@fortawesome/free-solid-svg-icons";

interface ReservationListProps {
    reservations: Reservation[];
    onDelete: (reservationId: string | undefined) => void
}

export default function ReservationList(props: ReservationListProps) {
    return (
        <Table striped bordered hover>
            <thead>
            <tr>
                <th>#</th>
                <th>Kto</th>
                <th>Od kiedy</th>
                <th>Do kiedy</th>
                <th>Liczba osób</th>
                <th/>
            </tr>
            </thead>
            <tbody>
            {Array.isArray(props.reservations) ? props.reservations.map((reservation, index) => {
                return (
                    <tr key={reservation.id}>
                        <td>{index + 1}</td>
                        <td>{reservation.who}</td>
                        <td>{moment(reservation.from).format('DD-MM-YYYY')}</td>
                        <td>{moment(reservation.to).format('DD-MM-YYYY')}</td>
                        <td>{reservation.numberOfPeople}</td>
                        <td>
                            <div className="d-flex flex-row">
                                <Button variant="outline-warning"><FontAwesomeIcon icon={faPenToSquare}/></Button>
                                <Button variant="outline-danger"
                                        onClick={() => props.onDelete(reservation.id)}><FontAwesomeIcon
                                    icon={faTrash}/></Button>
                            </div>
                        </td>
                    </tr>
                )
            }) : null}
            </tbody>
        </Table>
    );
}
