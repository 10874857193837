import {Card} from "primereact/card";
import "./faq.scss";
import {Element} from "react-scroll";

export default function Faq() {

    return (
        <Element name="faq" className="faq">
            <div className="faq__container">
                <div className="faq__header">
                    <h1>
                        Zadawane pytania
                    </h1>
                </div>
                <Card title="Czy możliwe jest zabranie psa lub kota do mieszkania?" className="mb-2">
                    <p className="m-0">
                        No problemo :D
                    </p>
                </Card>
                <Card title="Czy w okolicy są parkingi strzeżone?" className="mb-2">
                    <p className="m-0">
                        Tak, w najbliższej okolicy znajdują się trzy parkingi strzeżone całą dobę.
                    </p>
                </Card>
                <Card title="Czy w mieszkaniu można palić?" className="mb-2">
                    <p className="m-0">
                        W mieszkaniu jest kategoryczny zakaz palenia.
                    </p>
                </Card>
            </div>
        </Element>
    )
}
