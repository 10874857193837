import {AuthContext, AuthContextProps} from "./context/auth-context";
import {RouterProvider} from "react-router-dom";
import router from "./routes";
import React, {useState} from "react";
import {useAuth} from "./hooks/auth-hook";
import {addLocale, locale} from "primereact/api";
import {ApartmentEnum} from "./model/enums/apartment.enum";
import { ApartmentContext } from "./context/apartment-context";


export default function App() {
    const [apartment, setApartment] = useState<ApartmentEnum | undefined>(undefined);
    const [hintDisplayed, setHintDisplayed] = useState(false);

    const {token, login, logout, userId} = useAuth();

    addLocale('pl', {
        accept: "Tak",
        reject: "Nie",
        choose: "Wybierz",
        upload: "Wyślij",
        cancel: "Anuluj",
        dayNames: ["Niedziela", "Poniedziałek", "Wtorek", "Środa", "Czwartek", "Piątek", "Sobota"],
        dayNamesShort: ["niedz.", "pon.", "wt.", "śr.", "czw.", "pt.", "sob."],
        dayNamesMin: ["niedz.", "pon.", "wt.", "śr.", "czw.", "pt.", "sob."],
        monthNames: ["Styczeń", "Luty", "Marzec", "Kwiecień", "Maj", "Czerwiec", "Lipiec", "Sierpień", "Wrzesień", "Październik", "Listopad", "Grudzień"],
        monthNamesShort: ["STY", "LUT", "MAR", "KWI", "MAJ", "CZE", "LIP", "SIE", "WRZ", "PAŹ", "LIS", "GRU"],
        today: "Dziś",
        clear: "Wyczyść",
        weekHeader: "tydz.",
        firstDayOfWeek: 1,
        dateFormat: "dd/mm/yy",
        weak: "Słaby",
        medium: "Umiarkowany",
        strong: "Silny",
        passwordPrompt: "Podaj hasło"
    });

    locale('pl');

    return (
        <AuthContext.Provider
            value={
                new AuthContextProps(!!token, token, userId, login, logout)
            }>
            <ApartmentContext.Provider value={{apartment: apartment,
                hintDisplayed: hintDisplayed,
                setApartment: (apartment: ApartmentEnum | undefined) => setApartment(apartment),
                setHintDisplayed: () => setHintDisplayed(true)
            }}>
                <RouterProvider router={router}/>
            </ApartmentContext.Provider>
        </AuthContext.Provider>
    )

}
