export enum ErrorCodesEnum {
    FROM_CANNOT_BE_NULL = 'FROM_CANNOT_BE_NULL',
    TO_CANNOT_BE_NULL = 'TO_CANNOT_BE_NULL',
    FROM_CANNOT_BE_AFTER_TO = 'FROM_CANNOT_BE_AFTER_TO',
    FROM_AND_TO_CANNOT_BE_EQUAL = 'FROM_AND_TO_CANNOT_BE_EQUAL',
    WHO_CANNOT_BE_BLANK = 'WHO_CANNOT_BE_BLANK',
    NUMBER_OF_PEOPLE_CANNOT_BE_EMPTY = 'NUMBER_OF_PEOPLE_CANNOT_BE_EMPTY',
    NUMBER_OF_PEOPLE_MUST_BE_GREATER_THAN_ZERO = 'NUMBER_OF_PEOPLE_MUST_BE_GREATER_THAN_ZERO',
    APARTMENT_CANNOT_BE_BLANK = 'APARTMENT_CANNOT_BE_BLANK',
    RESERVATION_ID_CANNOT_BE_BLANK = 'RESERVATION_ID_CANNOT_BE_BLANK',
    RESERVATION_WITH_GIVEN_DATE_HAS_CONFLICT = 'Rezerwacja w podanym terminie koliduje z inną rezerwacją'
}
