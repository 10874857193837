import "./contaxt.scss";
import {useContext, useState} from "react";
import {ApartmentContext} from "../../../context/apartment-context";
import {ApartmentEnum} from "../../../model/enums/apartment.enum";
import {Button} from "primereact/button";
import {Element} from "react-scroll";

export default function Contact() {

    const [email, setEmail] = useState("");
    const [phoneNumber, setPhoneNumber] = useState("");

    const showEmail = () => {
        // TODO podpiąć statystyki
        setEmail("borkowsky@wp.pl");
    }

    const showPhoneNumber = () => {
        // TODO podpiąć statystyki
        setPhoneNumber("501 - 013 - 365");
    }

    const apartmentContext = useContext(ApartmentContext);

    const getApartmentImg = () => {
        if (apartmentContext.apartment === ApartmentEnum.LUNA) {
            return (
                <>
                    <div className="contact__view__header">
                        <h1>Rzut mieszkania z góry</h1>
                    </div>
                    <div className="contact__view__content">
                        <img src="/images/views/luna.png" alt="Luna view"/>
                    </div>
                </>
            )
        } else if (apartmentContext.apartment === ApartmentEnum.WEGA) {
            return (
                <>
                    <div className="contact__view__header">
                        <h1>Rzut mieszkania z góry</h1>
                    </div>
                    <div className="contact__view__content">
                        <img src="/images/views/wega.png" alt="Wega view"/>
                    </div>
                </>
            )
        } else {
            return null;
        }
    }

    return (

        <div className="contact">
            <div className="contact__container">
                {apartmentContext.apartment ?
                    <div className="contact__view">
                        {apartmentContext && apartmentContext.apartment ?
                            getApartmentImg()
                            : null}
                    </div>
                    : null}
                <Element name="contact"
                         className={apartmentContext.apartment !== undefined ? "contact__data-container__half-view" : "contact__data-container__full-view"}>
                    <h1>Dane kontaktowe</h1>
                    <div className="contact__data">
                        <span>Email: </span>
                        {email.length === 0 ?
                            <Button label="Pokaż email" severity="info" size="small" rounded
                                    onClick={() => showEmail()}/> : email}
                    </div>
                    <div className="contact__data">
                        <span>Telefon: </span>{phoneNumber.length === 0 ?
                            <Button label="Pokaż numer telefonu" severity="info" size="small" rounded
                                    onClick={() => showPhoneNumber()}/> : phoneNumber}
                    </div>
                </Element>
            </div>
        </div>
    );
}
