import {createContext} from "react";

export class AuthContextProps {
    readonly isLoggedIn: boolean;
    readonly userId: string | null;
    readonly token: string | null;
    readonly login: Function;
    readonly logout: Function;

    constructor(
        isLoggedIn: boolean,
        userId: string | null,
        token: string | null,
        login: Function,
        logout: Function
    ) {
        this.isLoggedIn = isLoggedIn
        this.userId = userId;
        this.token = token;
        this.login = login;
        this.logout = logout;
    }
}

export const AuthContext = createContext<AuthContextProps>( new AuthContextProps(false, null, null, (userId: string, token: string) => {}, () =>{}));