import {Alert, Button, Form} from "react-bootstrap";
import {useContext, useState} from "react";
import {AuthContext} from "../context/auth-context";
import {UserData} from "../model/user-data";
import {useNavigate} from "react-router-dom";

const AuthService = require("../services/auth")

export default function Login() {

    const initialStateOfUserData = {login: '', password: ''};
    const [userData, setUserData] = useState(initialStateOfUserData);
    const [validationError, setValidationError] = useState<string | null>(null);
    const auth = useContext(AuthContext);
    const navigate = useNavigate();

    const onChangeInput = (e: any) => {
        const {name, value} = e.target;

        setUserData({...userData, [name]: value});
    }

    let errorMessage;
    if (validationError) {
        if (validationError === 'INVALID_CREDENTIALS') {
            errorMessage =
                <div className="w-100">
                    <Alert key="wrong-credentials" variant="danger">
                        Dane do logowanie są nieprawidłowe!
                    </Alert>
                </div>
        } else if (validationError === 'SOMETHING_WENT_WRONG') {
            errorMessage =
                <div className="w-100">
                    <Alert key="wrong-credentials" variant="danger">
                        Coś poszło nie tak!
                    </Alert>
                </div>
        }
    } else {
        errorMessage = null;
    }


    const hideAlert = () => {
        setValidationError(null);
    }

    const handleSubmit = (event: any) => {
        event.preventDefault();
        AuthService.login(
            userData.login,
            userData.password,
            (response: UserData) => {
                auth.login(response.userId, response.token);
                setUserData(initialStateOfUserData);
                console.log("Zalogowano prawidłowo");
                navigate("/reservations", {replace: true})
            },
            (error: any) => {
                if (error.errorCode === 'AUTH_FAILED') {
                    setValidationError('INVALID_CREDENTIALS');
                    setTimeout(hideAlert, 3000);
                } else {
                    setValidationError('SOMETHING_WENT_WRONG');
                    setTimeout(hideAlert, 3000);
                }
                setUserData({...userData, password: ''});
            }
        )
    }

    const {login, password} = userData;

    return (
        <div className="login_modal__container">
            <Form onSubmit={handleSubmit}>
                {errorMessage}
                <div className="d-flex flex-column">
                    <div>
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                            <Form.Label>Login</Form.Label>
                            <Form.Control name='login' type="text" placeholder="Login" value={login}
                                          onChange={onChangeInput}/>
                        </Form.Group>
                    </div>
                    <div>
                        <Form.Group className="mb-3" controlId="formBasicPassword">
                            <Form.Label>Hasło</Form.Label>
                            <Form.Control name='password' type="password" placeholder="Hasło" value={password}
                                          onChange={onChangeInput}/>
                        </Form.Group>
                    </div>
                    <div className="d-flex justify-content-center">
                        <Button variant="primary" type="submit">
                            Zaloguj
                        </Button>
                    </div>
                </div>
            </Form>
        </div>
    );
}