import './navbar.scss';
import {useContext, useEffect, useState} from "react";
import {ApartmentContext} from "../../../context/apartment-context";
import {ApartmentEnum} from "../../../model/enums/apartment.enum";
import {Link} from 'react-scroll';

export default function Navbar() {

    const [showHint, setShowHint] = useState(false);
    const apartmentContext = useContext(ApartmentContext);

    const [showNavbar, setShowNavbar] = useState(false);

    useEffect(() => {
        const handleClickAnywhere = () => {
            setShowNavbar(false);
        };
        document.addEventListener('click', handleClickAnywhere, true);
        return () => {
            document.removeEventListener('click', handleClickAnywhere, true);
        };
    }, []);

    const onClickBurger = () => {
        setShowNavbar(true);
    }

    const clearContext = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        });
        setTimeout(() => {
            apartmentContext.setApartment(undefined);
        }, 200);
    }

    useEffect(() => {
        if (apartmentContext.apartment !== undefined && !apartmentContext.hintDisplayed) {
            setShowHint(true);
            setTimeout(() => {
                apartmentContext.setHintDisplayed();
                setShowHint(false);
            }, 10000);
        }
    }, [apartmentContext.apartment])

    const checkIfApartmentSelected = (apartment: ApartmentEnum) => {
        return apartmentContext.apartment === apartment;
    }

    const onApartmentClick = (apartment: ApartmentEnum) => {
        apartmentContext.setApartment(apartment);
    }

    return (
        <div className="navbar">
            <div className="navbar__container">
                <div className="navbar__logo-container">
                    <div className="navbar__logo">
                        <Link to="introduction" smooth={false} offset={-50} delay={100}>
                            <span
                                className={checkIfApartmentSelected(ApartmentEnum.LUNA) ? "navbar__apartment navbar__selected-apartment" : "navbar__apartment"}
                                onClick={() => onApartmentClick(ApartmentEnum.LUNA)}
                            >Luna</span>
                        </Link>
                        <span>&</span>
                        <Link to="introduction" smooth={false} offset={-50} delay={100}>
                            <span
                                className={checkIfApartmentSelected(ApartmentEnum.WEGA) ? "navbar__apartment navbar__selected-apartment" : "navbar__apartment"}
                                onClick={() => onApartmentClick(ApartmentEnum.WEGA)}
                            >Wega</span>
                        </Link>
                    </div>
                    {
                        showHint ?
                            <div className="navbar__logo-hint">
                                <div className="navbar__logo-hint__arrow">
                                    <img src="images/icons/white-left-arrow-icon.svg" alt="left arrow"></img>
                                </div>
                                <div className="navbar__logo-hint__text">
                                    <span>Wybierz apartament</span>
                                </div>
                            </div>
                            : null
                    }
                </div>
                <div className="navbar__hamburger-button" onClick={onClickBurger}>
                    <em className="pi pi-bars"></em>
                </div>
                <nav className="navbar__menu" style={showNavbar ? {display: "flex"} : undefined}>
                    <ul>
                        <li className="navbar__item">
                            <div onClick={clearContext}>
                                Home
                            </div>
                        </li>
                        {apartmentContext.apartment ?
                            <>
                                <li className="navbar__item">
                                    <Link to="equipment" smooth={false} offset={-100} duration={200}>
                                        Wyposażenie
                                    </Link>
                                </li>
                                <li className="navbar__item">
                                    <Link to="gallery" smooth={false} offset={-100} duration={200}>
                                        Galeria
                                    </Link>
                                </li>
                                <li className="navbar__item">
                                    <Link to="location" smooth={false} offset={-70} duration={200}>
                                        Lokalizacja
                                    </Link>
                                </li>
                                <li className="navbar__item">
                                    <Link to="faq" smooth={false} offset={-70} duration={200}>
                                        Pytania
                                    </Link>
                                </li>
                                <li className="navbar__item">
                                    <Link to="calendar" smooth={false} offset={-100} duration={200}>
                                        Kalendarz
                                    </Link>
                                </li>
                            </> :
                            <>
                                <li className="navbar__item">
                                    <Link to="introduction" smooth={false} offset={-75} duration={200}>
                                        O nas
                                    </Link>
                                </li>
                                <li className="navbar__item">
                                    <Link to="apartment-selector" smooth={false} offset={-100} duration={200}>
                                        Apartamenty
                                    </Link>
                                </li>
                            </>
                        }
                        <li className="navbar__item">
                            <Link to="contact" smooth={false} offset={-100} duration={200}>
                                Kontakt
                            </Link>
                        </li>
                    </ul>
                </nav>
            </div>
        </div>
    )
}
