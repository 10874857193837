import {ApartmentEnum} from "../../../model/enums/apartment.enum";

export type Apartment = {
    name: string,
    images: Image[]
}

export type Image = {
    itemImageSrc: string,
    thumbnailImageSrc: string,
    alt: string,
}

export const images: Apartment[] = [
    {
        name: ApartmentEnum.LUNA,
        images: [
            {
                "itemImageSrc": "images/items/luna/IMG_3794.jpg",
                "thumbnailImageSrc": "images/items/luna/thumbnails/IMG_3794.jpg",
                "alt": "Luna - pokój środkowy",
            },
            {
                "itemImageSrc": "images/items/luna/IMG_3795.jpg",
                "thumbnailImageSrc": "images/items/luna/thumbnails/IMG_3795.jpg",
                "alt": "Luna - widok na kuchnie",
            },
            {
                "itemImageSrc": "images/items/luna/IMG_3796.jpg",
                "thumbnailImageSrc": "images/items/luna/thumbnails/IMG_3796.jpg",
                "alt": "Luna - pokój 1",
            },
            {
                "itemImageSrc": "images/items/luna/IMG_3797.jpg",
                "thumbnailImageSrc": "images/items/luna/thumbnails/IMG_3797.jpg",
                "alt": "Luna - pokój 1 drugi widok",
            },
            {
                "itemImageSrc": "images/items/luna/IMG_3798.jpg",
                "thumbnailImageSrc": "images/items/luna/thumbnails/IMG_3798.jpg",
                "alt": "Luna - kuchnia",
            },
            {
                "itemImageSrc": "images/items/luna/IMG_3799.jpg",
                "thumbnailImageSrc": "images/items/luna/thumbnails/IMG_3799.jpg",
                "alt": "Luna - pokój 2",
            },
            {
                "itemImageSrc": "images/items/luna/IMG_3800.jpg",
                "thumbnailImageSrc": "images/items/luna/thumbnails/IMG_3800.jpg",
                "alt": "Luna - kuchnia 2",
            },
            {
                "itemImageSrc": "images/items/luna/IMG_3793.jpg",
                "thumbnailImageSrc": "images/items/luna/thumbnails/IMG_3793.jpg",
                "alt": "Luna - łazienka",
            }
        ]
    },
    {
        name: ApartmentEnum.WEGA,
        images: [
            {
                "itemImageSrc": "images/items/wega/IMG_3789.jpg",
                "thumbnailImageSrc": "images/items/wega/thumbnails/IMG_3789.jpg",
                "alt": "Wega - pokój duży",
            },
            {
                "itemImageSrc": "images/items/wega/IMG_3787.jpg",
                "thumbnailImageSrc": "images/items/wega/thumbnails/IMG_3787.jpg",
                "alt": "Wega - kuchnia",
            },
            {
                "itemImageSrc": "images/items/wega/IMG_3788.jpg",
                "thumbnailImageSrc": "images/items/wega/thumbnails/IMG_3788.jpg",
                "alt": "Wega - pokój 1",
            },
            {
                "itemImageSrc": "images/items/wega/IMG_3802.jpg",
                "thumbnailImageSrc": "images/items/wega/thumbnails/IMG_3802.jpg",
                "alt": "Wega - pokój duży 2",
            },
            {
                "itemImageSrc": "images/items/wega/IMG_3791.jpg",
                "thumbnailImageSrc": "images/items/wega/thumbnails/IMG_3791.jpg",
                "alt": "Wega - pokój środkowy",
            },
            {
                "itemImageSrc": "images/items/wega/IMG_3792.jpg",
                "thumbnailImageSrc": "images/items/wega/thumbnails/IMG_3792.jpg",
                "alt": "Wega - pokój środkowy 2",
            },
            {
                "itemImageSrc": "images/items/wega/IMG_3801.jpg",
                "thumbnailImageSrc": "images/items/wega/thumbnails/IMG_3801.jpg",
                "alt": "Wega - pokój duży 3",
            },
            {
                "itemImageSrc": "images/items/wega/IMG_3803.jpg",
                "thumbnailImageSrc": "images/items/wega/thumbnails/IMG_3803.jpg",
                "alt": "Wega - pokój środkowy 3",
            },
            {
                "itemImageSrc": "images/items/wega/IMG_3804.jpg",
                "thumbnailImageSrc": "images/items/wega/thumbnails/IMG_3804.jpg",
                "alt": "Wega - pokój mały",
            },
            {
                "itemImageSrc": "images/items/wega/kuchnia.jpg",
                "thumbnailImageSrc": "images/items/wega/kuchnia.jpg",
                "alt": "Wega - kuchnia 2",
            },
            {
                "itemImageSrc": "images/items/wega/IMG_3786.jpg",
                "thumbnailImageSrc": "images/items/wega/thumbnails/IMG_3786.jpg",
                "alt": "Wega - łazienka",
            }
        ]
    }
]
