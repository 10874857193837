import {ApartmentEnum} from "../../../model/enums/apartment.enum";

export type Room = {
    name: string;
    equipments: Equipment[]
}

export type Equipment = {
    icon: string;
    description: string;
    exclusions?: ApartmentEnum;
}

export const rooms = [
    {
        name: "Sypialnie",
        equipments: [
            {
                icon: "/images/icons/bedroom-icon.svg",
                description: "2 sypialnie, każda z jedną dużą sofą, rozkładaną do szerokości 150 cm",
                exclusions: ApartmentEnum.WEGA
            },
            {
                icon: "/images/icons/bedroom-icon.svg",
                description: "1 sypialnia z jedną dużą sofą, rozkładaną do szerokości 150cm",
                exclusions: ApartmentEnum.LUNA
            },
            {
                icon: "/images/icons/single-bed-bedroom-icon.svg",
                description: "1 sypialnia z dwoma łóżkami pojedynczymi, z małym balkonem",
                exclusions: ApartmentEnum.WEGA
            },
            {
                icon: "/images/icons/single-bed-bedroom-icon.svg",
                description: "2 sypialnie, każda z dwoma pojedynczymi łóżkami, jedna z małym balkonem",
                exclusions: ApartmentEnum.LUNA
            },
            {
                icon: "/images/icons/furniture-icon.svg",
                description: "W każdej sypialni biurko lub stół, krzesła, komody, szafy",
            },
            {
                icon: "/images/icons/pillow-icon.svg",
                description: "Do dyspozycji gości pełny zestaw pościeli w cenie wynajmu",
            },
            {
                icon: "/images/icons/screen-icon.svg",
                description: "Telewizor z płaskim ekranem z dostęp do telewizji kablowej z ok. 300 programami",
            },
            {
                icon: "/images/icons/wi-fi-icon.svg",
                description: "Szybkie Wi-Fi",
            },
            {
                icon: "/images/icons/table-fan-icon.svg",
                description: "Wentylator biurkowy",
            },
            {
                icon: "/images/icons/laundry-iron-high-temperature-icon.svg",
                description: "Żelazko z deską do prasowania",
            },
            {
                icon: "/images/icons/clothes-dryer.jpg",
                description: "Suszarka składana do ubrań",
            }
        ]
    },
    {
        name: "Łazienka",
        equipments: [
            {
                icon: "/images/icons/bathtub-icon.svg",
                description: "Wanna",
                exclusions: ApartmentEnum.WEGA
            },
            {
                icon: "/images/icons/shower-line-icon.svg",
                description: "Kabina prysznicowa",
                exclusions: ApartmentEnum.LUNA
            },
            {
                icon: "/images/icons/towel-icon.svg",
                description: "Komplet ręczników",
            },
            {
                icon: "/images/icons/washing-machine-icon.svg",
                description: "Pralka",
            }

        ]
    },
    {
        name: "Kuchnia",
        equipments: [
            {
                icon: "/images/icons/dishwasher.png",
                description: "Zmywarka",
                exclusions: ApartmentEnum.WEGA
            },
            {
                icon: "/images/icons/electric-kettle-icon.svg",
                description: "Czajnik",
            },
            {
                icon: "/images/icons/fridge-icon.svg",
                description: "Lodówka z zamrażalnikiem",
            },
            {
                icon: "/images/icons/microwave-icon.svg",
                description: "Mikrofala",
            },
            {
                icon: "/images/icons/kitchen-stove-icon.svg",
                description: "Kuchenka gazowa",
            },
            {
                icon: "/images/icons/oven-icon.svg",
                description: "Piekarnik elektryczny",
            },
            {
                icon: "/images/icons/dishes-icon.svg",
                description: "Duży zestaw naczyń kuchennych",
            }
        ]
    }
]
