import {ApartmentEnum} from "../model/enums/apartment.enum";
import {createContext} from "react";

export type ApartmentContextType = {
    apartment: ApartmentEnum | undefined;
    hintDisplayed: boolean;
    setApartment: (apartment: ApartmentEnum | undefined) => void;
    setHintDisplayed: () => void
}

export const ApartmentContext = createContext<ApartmentContextType>({apartment: undefined, hintDisplayed:false, setApartment: () => {}, setHintDisplayed: () => {}});
