import {ApartmentEnum} from "./enums/apartment.enum";

export class Reservation {
    id?: string;
    who: string;
    from: Date;
    to: Date;
    numberOfPeople: number;
    apartment: ApartmentEnum;

    constructor(
        who: string,
        from: Date,
        to: Date,
        numberOfPeople: number,
        apartment: ApartmentEnum,
        id = undefined
    ) {
        this.who = who;
        this.from = from;
        this.to = to;
        this.numberOfPeople = numberOfPeople;
        this.apartment = apartment;
    }
}
