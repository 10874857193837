import {GoogleMap, LoadScript, MarkerF, useGoogleMap} from "@react-google-maps/api";
import "./map.scss";
import {Element} from "react-scroll";
import {useEffect} from "react";

export default function Map() {

    const containerStyle = {
        width: '100%',
        height: '80vh'
    };

    const center = {
        lat: 54.41037458877169, lng: 18.59816885183836
    };

    return (
        <Element name="location" className="map">
            <div className="map__header">
                <h1>Lokalizacja</h1>
            </div>

            <div className="map__locations">
                <div className="map__locations__header">
                    <h3>
                        Odległości od ważniejszych trójmiejskich atrakcji:
                    </h3>
                </div>
                <div className="map__locations__content">
                    <ul>
                        <li>plaża w Jelitkowie – ok. 1 km pieszo (15 min.)</li>
                        <li>molo w Sopocie – 3,3 km pieszo (40 min.)</li>
                        <li>molo w Gdańsku Brzeźnie – 3,3 km (40 min.)</li>
                        <li>Stare Miasto w Gdańsku – 11 km</li>
                        <li>Park Oliwski – 2,1 km pieszo (30 min.)</li>
                        <li>ZOO w Oliwie – 4 km</li>
                        <li>ERGO Arena – 600 m</li>
                        <li>Aquapark w Sopocie – 6 km</li>
                        <li>Przystań tramwaju wodnego na Hel w Sopocie – 3,5 km</li>
                        <li>przystanki tramwajowe i autobusowe – 350 m</li>
                        <li>stacja kolejki miejskiej SKM Żabianka – 1,4 km</li>
                        <li>stacja kolejowa Gdańsk-Oliwa – 3 km</li>
                        <li>wypożyczalnia rowerów – 1,1 km</li>
                        <li>bezpłatny parking – w bezpośrednim sąsiedztwie (szlaban otwierany pilotem)</li>
                        <li>parkingi płatne – w pobliżu</li>
                        <li>sklepy spożywcze – 300 m</li>
                        <li>apteka – 200 m</li>
                        <li>bar, restauracja (śniadania, obiady, kolacje – smacznie i niedrogo) – 650 m</li>
                        <li>Smażalnia ryb – 1,2 km</li>
                    </ul>
                </div>
            </div>
            <div className="map__container">
                <LoadScript
                    googleMapsApiKey="AIzaSyDmpSGqidz5OcKovIKgedFJ_A3pQWNwnHI"
                >
                    <GoogleMap
                        mapContainerStyle={containerStyle}
                        center={center}
                        zoom={13}
                    >
                        {<MarkerF position={{lat: 54.423122245335115, lng: 18.58506038507617}} label="Orłowska"></MarkerF>}
                        <></>
                    </GoogleMap>
                </LoadScript>

            </div>
        </Element>
    )
}
