import {Galleria} from "primereact/galleria";
import {useContext, useRef, useState} from "react";
import './gallery.scss';
import {Element} from "react-scroll";
import {ApartmentContext} from "../../../context/apartment-context";
import {Image, images} from "./images";

export default function Gallery() {

    const [activeIndex, setActiveIndex] = useState<number>(0);
    const galleria = useRef<Galleria>(null);

    const apartmentContext = useContext(ApartmentContext);

    const itemTemplate = (item: Image) => {
        return <img src={item.itemImageSrc} alt={item.alt} style={{width: '100%'}}/>
    }

    const thumbnailTemplate = (item: Image) => {
        return <img src={item.thumbnailImageSrc} alt={item.alt}/>
    }

    const getImages = () => {
        return images.filter(it => it.name === apartmentContext.apartment)[0].images;
    }

    return (
        <div className="gallery">
            <Element name="gallery" className="gallery__container">
                <div className="gallery__header">
                    <h1>
                        Galeria
                    </h1>
                </div>
                <Galleria ref={galleria} value={getImages()} numVisible={7} style={{maxWidth: '850px'}}
                          activeIndex={activeIndex} onItemChange={(e) => setActiveIndex(e.index)}
                          circular fullScreen showItemNavigators showThumbnails={false} item={itemTemplate}
                          itemNextIcon={undefined} itemPrevIcon={undefined} nextThumbnailIcon={undefined}
                          prevThumbnailIcon={undefined}
                          thumbnail={thumbnailTemplate}/>
                <div className="gallery__images">
                    {
                        images && images.filter(it => it.name === apartmentContext.apartment).map((apartment) => {
                            return (apartment.images.map((image, index) => {
                                    let imgEl = <img className="gallery__image" src={image.thumbnailImageSrc}
                                                     alt={image.alt}
                                                     style={{cursor: 'pointer'}} onClick={
                                        () => {
                                            setActiveIndex(index);
                                            galleria.current?.show()
                                        }
                                    }/>
                                    return (
                                        <div className="gallery__image-container" key={index}>
                                            {imgEl}
                                        </div>
                                    )
                                })
                            )
                        })
                    }
                </div>
            </Element>
        </div>
    )
}
