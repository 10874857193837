import {Element} from "react-scroll";

import "./introduction.scss";

export default function Introduction() {

    return (
        <Element name="introduction" className="introduction">
            <div className="introduction__header">
                <h1>Zapraszamy do dwóch trzypokojowych apartamentów w Gdansku Żabiance</h1>
            </div>
            <hr className="introduction__line"></hr>
            <div className="introduction__text">
                <span><strong>Apartamenty LUNA i WEGA</strong> znajdują się na ul. Orłowskiej, w gdańskiej dzielnicy Żabianka, na granicy z Uzdrowiskiem Sopot. Apartamenty sąsiadują ze sobą.
                    Znajdują się ok. 1000 metrów od morza i plaży leżącej w Gdańsku Jelitkowie, ok. 3 km od Mola w Sopocie oraz ok. 11 km od gdańskiego Starego Miasta.</span>
            </div>
        </Element>
    );
}
